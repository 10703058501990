
<script setup lang="ts">
    import { ref, watch, computed } from "vue";
    import { OActionList, OActionItem } from "o365-ui-components";
    import { MBottomSheet } from "o365-mobile";
    import { OFileUploadButton, OAttachments } from "o365-fileupload";
    import { OTextArea } from "o365-ui-components";
    import { DataObject, DataRow } from "o365-data-object";
    import OActionSaveCancel from "./Actions.SaveCancel.vue";
    import useCheckItems from "./CheckItems.Composables.useCheckItems.ts";

    export interface Props {
        dataObject: DataObject;
        row: DataRow;
        readonly?: boolean;
        checkItems: ReturnType<typeof useCheckItems>;
    }

    const props = withDefaults(defineProps<Props>(), { readonly: false });

    const dsAttachments = $getDataObjectById("dsCheckItems_Attachments");
    const dsAttachmentsFilter = (row: DataRow) => row.CheckItem_ID == props.row.ID;

    const scopeItems = computed(() => props.checkItems.scopeItemsForCheckItem(props.row.ID));

    const editingComment = ref<boolean>(false);
    const textAreaRef = ref<OTextArea>(null);
    const editingUrl = ref<boolean>(false);
    const uploadBtnRef = ref<OFileUploadButton>(null);

    function uploadFile() {
        if (uploadBtnRef.value) {
            uploadBtnRef.value.click();
        }
    }

    watch([ textAreaRef, editingComment ], () => {
        if (textAreaRef.value && editingComment.value) {
            textAreaRef.value.activateEditor();
        }
    });
</script>

<template>
    <template v-if="row.IsHeader">
        <div class="px-2">
            <div class="fw-medium">
                <span>{{ row.Item }}</span>
                <span> - </span>
                <span>{{ row.Description }}</span>
            </div>
        </div>
    </template>
    <template v-else>
        <div class="position-relative bg-white border" style="padding: 0.5rem; border-radius: 0.5rem !important; box-shadow: 2px 4px 8px rgba(0%, 0%, 0%, 5%);">
            <!-- upload button for item (must remain hidden) -->
            <div class="d-none">
                <OFileUploadButton
                    ref="uploadBtnRef"
                    customBtnMarkup
                    :data-object="dsAttachments"
                    multiple="multiple"
                    :beforeUpload="() => checkItems.onBeforeFileUpload(row.ID)"
                    @uploaded="dataObject.refreshRow();"
                />
            </div>
            
            <!-- title -->
            <div class="p-1 d-flex align-items-center">
                <div class="flex-grow-1 fw-medium">
                    {{ row.Item }}
                </div>
                <div class="flex-shrink-0 d-flex" style="gap: 1.5rem;">
                    <div class="title-button">
                        <a :title="$t('Create new Scope Item')" @click="checkItems.createNewScopeItem(row.ID)" >
                            <i class="bi bi-plus-circle" />
                        </a>
                    </div>
                    <div class="title-button">
                        <a :title="$t('Add attachment')">
                            <i class="bi bi-cloud-arrow-up" @click="uploadFile()" />
                        </a>
                    </div>
                    <div class="title-button">
                        <a :title="$t('Add comment')" @click="editingComment = !editingComment">
                            <i class="bi bi-chat" />
                        </a>
                    </div>
                </div>
            </div>


            <!-- <template v-if="false"> -->
                <!-- <div class="mt-1 px-1 font-n1"> -->
                    <!-- <template v-if="!readonly"> -->
                        <!-- <OActionList> -->
                            <!-- <template #target="{ target }"> -->
                                <!-- <a :ref="target" class="float-end d-flex justify-content-center align-items-center" style="width: 2.5rem; height: 2.5rem; margin-top: -0.5rem; margin-right: -0.5rem; cursor: pointer;"> -->
                                    <!-- <i class="bi bi-three-dots text-black font-4" /> -->
                                <!-- </a> -->
                            <!-- </template> -->
                            <!-- <OActionItem :text="row.Comments ? $t('Edit comment') : $t('Add comment')" icon="bi-chat" @click="editingComment = true" /> -->
                            <!-- <OActionItem :text="row.ContentURL ? $t('Edit url') : $t('Add url')" icon="bi bi-box-arrow-up-right" @click="editingUrl = true" /> -->
                            <!-- <OActionItem :text="$t('Add attachment')" icon="bi bi-cloud-arrow-up" @click="uploadFile" /> -->
                            <!-- <OActionItem :text="$t('Create new Scope Item')" icon="bi bi-plus-circle" @click="checkItems.createNewScopeItem(row.ID)" /> -->
                        <!-- </OActionList> -->
                        <!-- <div class="d-none"> -->
                            <!-- <OFileUploadButton -->
                                <!-- ref="uploadBtnRef" -->
                                <!-- customBtnMarkup -->
                                <!-- :data-object="dsAttachments" -->
                                <!-- multiple="multiple" -->
                                <!-- :beforeUpload="() => checkItems.onBeforeFileUpload(row.ID)" -->
                                <!-- @uploaded="dataObject.refreshRow();" -->
                            <!-- /> -->
                        <!-- </div> -->
                    <!-- </template> -->
                    <!-- <div> -->
                        <!-- <span class="fw-medium">{{ row.Item }}</span> -->
                        <!-- <span> - </span> -->
                        <!-- <span>{{ row.Description }}</span> -->
                    <!-- </div> -->
                    <!-- <div class="clear-both" /> -->
                <!-- </div> -->
            <!-- </template> -->

            <!-- body -->
            <div class="mt-1 mx-1 d-flex flex-column gap-1 position-relative">
                <!-- description -->
                <div>
                    {{ row.Description }}
                </div>

                <!-- comments -->
                <template v-if="row.Comments || editingComment">
                    <div>
                        <div class="ms-1 font-n2 text-muted fw-medium underline">
                            {{ $t("Comments:") }}
                        </div>
                        <div class="mt-1" @click="editingComment = true">
                            <template v-if="editingComment">
                                <OTextArea ref="textAreaRef" class="form-control" v-model="row.Comments" auto-grow no-resize />
                            </template>
                            <template v-else>
                                <OTextArea class="form-control bg-light" v-model="row.Comments" auto-grow no-resize readonly />
                            </template>
                        </div>
                    </div>
                    <OActionSaveCancel class="justify-content-end" style="margin-top: 0.75rem; margin-bottom: 0.25rem;" v-model="editingComment" :row="row" />
                </template>

                <!-- url -->
                <template v-if="row.ContentURL">
                    <div>
                        <div
                            class="cornsilk py-1 px-2 mt-2 border"
                            style="cursor: pointer; background-color: cornsilk; border-radius: 0.5rem;"
                            v-if="row.ContentURL"
                        >
                            Url:
                            <a :href="decodeURIComponent(row.ContentURL)" target="_blank" style="overflow-wrap:break-word;">
                                {{ row.ContentURLAlias }}
                            </a>
                        </div>
                    </div>
                </template>

                <!-- extra info -->
                <!-- <template v-if="row.ExtraInformationText"> -->
                    <!-- <div class="extraTextInfo mt-2" style="font-style: italic;flex:1" v-if="row.ExtraInformationText">{{ row.ExtraInformationText }}</div> -->
                <!-- </template> -->

                <!-- scope items -->
                <template v-if="scopeItems.length">
                    <div>
                        <!-- <div style="font-weight: 600;text-decoration:underline;font-size:smaller" class="text-muted mt-1">{{ $t("Scope Items:") }}</div> -->
                        <div class="ms-1 font-n2 fw-medium underline text-muted">
                            {{ $t("Scope Items:") }}
                        </div>
                        <div class="mt-1 d-flex flex-column gap-2">
                            <template v-for="item in scopeItems">
                                <div class="d-flex border" style="border-radius: 0.5rem;">
                                    <a class="flex-grow-1" style="padding: 0.5rem 0.75rem;" :href="`/nt/${item.DetailApp_ID}?ID=${item.Item_ID}`" target="_blank">
                                        <span>{{ item.Item_ID }} - {{ item.Title }}</span>
                                        <span class="italic" v-if="item.CarAorB">{{ item.CatAorB }}</span>
                                    </a>
                                    <div class="flex-shrink-0 border-start" />
                                    <a
                                        class="flex-shrink-0 d-flex justify-content-center align-items-center text-black"
                                        style="height: 2.5rem; width: 2.5rem; cursor: pointer;"
                                        @click="checkItems.removeScopeItemConnection(item.Item_ID)"
                                    >
                                        <i class="bi bi-x" />
                                    </a>
                                </div>
                            </template>
                        </div>
                    </div>
                </template>

                <!-- attachments -->
                <template v-if="true">
                    <OAttachments :dataObject="dsAttachments" :filterCallback="dsAttachmentsFilter" />
                </template>
            </div>

            <div class="mt-2">
                <div class="d-flex font-n2" style="gap: 0.5rem;">
                    <button class="checklist-button ver-2" :class="{ 'active': (row.oldValues.Status ?? row.Status) === -1 }" @click="checkItems.setStatus(row, -1)">
                        <div v-if="row.oldValues.Status !== undefined && row.Status === -1" class="spinner-border spinner-border-sm"></div>
                        <div v-else>{{ $t("Not checked") }}</div>
                    </button>
                    <button class="checklist-button ver-2" :class="{ 'active': (row.oldValues.Status ?? row.Status) === 1 }"  @click="checkItems.setStatus(row, 1)">
                        <div v-if="row.oldValues.Status !== undefined && row.Status === 1" class="spinner-border spinner-border-sm"></div>
                        <div v-else>{{ $t("N/A") }}</div>
                    </button>
                    <button class="checklist-button ver-2" :class="{ 'active': (row.oldValues.Status ?? row.Status) === 0 }"  @click="checkItems.setStatus(row, 0)">
                        <div v-if="row.oldValues.Status !== undefined && row.Status === 0" class="spinner-border spinner-border-sm"></div>
                        <div v-else>{{ $t("Checked") }}</div>
                    </button>
                </div>
            </div>
        </div>
    </template>
    <MBottomSheet :title="$t('URL')" v-model="editingUrl" halfscreen @hide="row.cancelChanges()">
        <div class="p-3 d-flex flex-column gap-3">
            <div>
                <div>URL</div>
                <OTextArea class="form-control" v-model="row.ContentURL" />
            </div>
            <div>
                <div>URL Alias</div>
                <OTextArea class="form-control" v-model="row.ContentURLAlias" />
            </div>
            <OActionSaveCancel grow :row="row" @done="editingUrl = false" />
        </div>
    </MBottomSheet>
</template>

<style scoped>
    .checklist-button {
        background: white;
        border: none;
        color: black;

        flex: 1;

        border-radius: 0;

        padding: 0.75rem 0.25rem;

        border-top: 1px solid var(--bs-gray-300);

        &:not(:last-child) {
            border-right: 1px solid var(--bs-gray-300);
        }

        &:first-child {
            border-radius: 0 0 0 0.5rem;
        }

        &:last-child {
            border-radius: 0 0 0.5rem 0;
        }

        transition: all 100ms ease-in-out;

        &.active {
            font-weight: 500;

            &:first-child {
                /* background: var(--bs-danger-bg-subtle); */
                background-color: #ef4444;
                color: var(--bs-white);
            }
            &:not(:first-child):not(:last-child) {
                /* background: var(--bs-gray-700); */
                background-color: #292524;
                color: var(--bs-white);
            }
            &:last-child {
                /* background: var(--bs-success-bg-subtle); */
                background-color: #22c55e;
                color: var(--bs-white);
            }
        }



    /*
    &.item-status-not-checked {
        &.active {
            background-color: #ef4444;
            color: white;
        }
    }

    &.item-status-na {
        &.active {
            background-color: #292524;
            color: white;
        }
    }

    &.item-status-checked {
        &.active {
            background-color: #22c55e;
            color: white;
        }
    }
    */


        &.ver-2 {
            border-radius: 0.5rem;
            border: 1px solid var(--bs-gray-300);
        }
    }
</style>

<style scoped>
    .title-button {
        position: relative;
        width: 1.5em;
        height: 1.5em;

        > a {
            position: absolute;
            inset: -0.5rem;

            display: flex;
            justify-content: center;
            align-items: center;

            cursor: pointer;

            > i {
                font-size: 1.5em;
            }
        }
    }
</style>
